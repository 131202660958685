.red-level {
  stroke: #e83e6d;
  border-color: #e83e6d;
  color: #e83e6d;
}

.orange-level {
  stroke: #f49855;
  border-color: #f49855;
  color: #f49855;
}

.blue-level {
  stroke: #2e48a7;
  border-color: #2e48a7;
  color: #2e48a7;
}

.green-level {
  stroke: #42a37f;
  border-color: #42a37f;
  color: #42a37f;
}

.unknown-level {
  stroke: #cccccc;
  border-color: #cccccc;
  color: #cccccc;
}
